import React from "react"
import { Link } from "gatsby"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          nome: '',
          email: '',
          messaggio: '',
          acceptance: false,
          invio: false
        };
    
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this);
      }
    
      handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      }

      handleSubmit(event) {
        event.preventDefault();
        const data = JSON.stringify({ nome: this.state.nome, email: this.state.email, messaggio: this.state.messaggio })
        console.log(data);
        fetch('https://www.ristorante-ilcacciatore.it/form.php', {
        method: 'POST',
        body: data,
        });
        this.setState({
          invio: true
        });
      }

      resetForm(event){
        this.setState ({
          nome: '',
          email: '',
          messaggio: '',
          acceptance: false,
          invio: false
        });
      }
    
      render() {
        if(this.state.invio){
          return(
            <div className="ricevuta">
              <h2>Grazie per averci scritto</h2>
              <h3>Ti risponderemo al più presto</h3>
              <button className="button" onClick={this.resetForm}>Scrivici di nuovo</button>
            </div>
            
          );
        } else {
        return (
          <form onSubmit={this.handleSubmit}>
            <h2>{this.props.formTitle}</h2>
            <div className="row">
                    <div className="col-md-6">
                        <input type="text" value={this.state.nome} onChange={this.handleInputChange} name="nome" id="nome" placeholder="Nome" required="" />
                    </div>
                    <div className="col-md-6">
                        <input type="email" value={this.state.email} onChange={this.handleInputChange} name="email" id="email" placeholder="Email" required="" />
                    </div>
                    <div className="col-md-12">
                        <textarea rows="15" value={this.state.messaggio} onChange={this.handleInputChange} name="messaggio" id="messaggio" placeholder="Scrivi qui il tuo messaggio" required=""></textarea>
                    </div>
                    <div className="col-md-12">
                        <input type="checkbox" name="acceptance" checked={this.state.isGoing} onChange={this.handleInputChange} required="" /> Ho letto e accetto la <Link to="/privacy-policy">Privacy Policy</Link> <br/>
                        <input type="submit" value="Invia" />
                    </div>
            </div>
          </form>
        );
        }
      }
  }

  export default Form;