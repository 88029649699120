import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Facebook from '../images/facebook.inline.svg'
import Instagram from '../images/instagram.svg'
import Trip from '../images/tripadvisor.svg'

import Main from "../css/main.css"

const Header = ({ siteTitle }) => (
        <header>
          <div className="custom-container">
              <div className="social">
                  <a href="https://www.facebook.com/ristoranteilcacciatore81/" target="_blank" rel="noopener noreferrer"><Facebook /></a>
                  <a href="https://www.instagram.com/ristoranteilcacciatore18/" target="_blank" rel="noopener noreferrer"><Instagram /></a>
                  <a href="https://www.tripadvisor.it/Restaurant_Review-g1544147-d3637249-Reviews-Il_Cacciatore-Cercemaggiore_Province_of_Campobasso_Molise.html" target="_blank" rel="noopener noreferrer"><Trip /></a>
              </div>
              <div className="logo">
                <Link to="/" className="logo">{siteTitle}</Link>
              </div>
              <div className="telefono">0874 799694</div>
          </div>
        </header>

)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
